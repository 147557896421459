<template>
  <div class="ws-poll">
    <div v-if="showPoll" class="quiz">
      <poll-test-container module-type="test"></poll-test-container>
    </div>

    <div v-else class="init-message">
      <span>{{ $t("testNGames.thankForYourAnswers") }}</span>
      <poll-control-button @click="close">
        {{ $t("testNGames.close") }}
      </poll-control-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import PollTestContainer from "@/components/common/polls/PollTestContainer";
import PollControlButton from "@/components/common/polls/components/common/PollControlButton";
import { polls } from "@/store/modules/store.namespaces";
import { RUN_ABOUT_WS_POLL } from "@/store/modules/polls/action-types";
import { SET_WS_POLL_MODAL_OPEN } from "@/store/modules/polls/mutation-types";
import { test } from "@/constants/polls/polls-module-types";

export default {
  name: "WsPoll",
  components: { PollControlButton, PollTestContainer },
  computed: {
    ...mapState(polls, ["wsPollComplete"]),
    ...mapState(`${polls}/${test}`, ["activePoll"]),
    showPoll() {
      return !this.wsPollComplete && this.activePoll;
    },
  },
  created() {
    this.runAboutWsPoll();
  },
  methods: {
    ...mapMutations(polls, {
      setWSPollModalOpen: SET_WS_POLL_MODAL_OPEN,
    }),
    ...mapActions(polls, {
      runAboutWsPoll: RUN_ABOUT_WS_POLL,
    }),
    close() {
      this.setWSPollModalOpen(false);
    },
  },
};
</script>

<style scoped lang="less">
.ws-poll {
  position: relative;
  .content-close {
    position: absolute;
    transform: scale(1.3);
    top: -7px;
    right: 10px;
    .closebtn {
      width: 20px;
      height: 20px;
      opacity: 0.5;
      font-size: 20px;
      color: #000000;
    }
  }
  .progress-bar {
    margin-bottom: 30px;
  }
  .init-message {
    text-align: center;
    margin-top: 50px;
    span {
      display: inline-block;
      margin: 15px 0;
      width: 100%;
      font-size: 21px;
    }
    ::v-deep .poll-control-button {
      margin: 10px auto;
    }
  }
}
</style>
